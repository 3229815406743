.logo {
  float: left;
  width: 280px;
  height: 60px;
  background: rgba(255, 255, 255, 0);
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

#container{
  height: 100%;
}